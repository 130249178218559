import { getConfig } from '@/api/index'

const state = {
  wechat: {
    qrcode: '',
    name: ''
  },
  logo: '',
  device: 'desktop',
  headers: {},
  categorys: []

}

const mutations = {
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  TOGGLE_WECHAT: (state, wechat) => {
    state.wechat = wechat
  },
  TOGGLE_LOGO: (state, logo) => {
    state.logo = logo
  },
  TOGGLE_CATEGORYS: (state, categorys) => {
    state.categorys = categorys
  }
}

const actions = {
  async getSiteInfo({ commit }) {
    const data = await getConfig()
    commit('TOGGLE_WECHAT', data.wechat)
    commit('TOGGLE_LOGO', data.header.logo)
    commit('TOGGLE_CATEGORYS', data.header.categorys)
    return data
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
