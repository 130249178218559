<template>
  <div class="com">
    <div v-if="device === 'desktop'" class="padding" />
    <el-menu
      mode="horizontal"
      :default-active="activeIndex"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
    >
      <el-menu-item index="首页" @click="goHome">
        首页
      </el-menu-item>

      <template>
        <el-menu-item
          v-for="item in categorys"
          :key="item.name"
          :index="item.name"
          @click="goDetialList(item.name)"
        >
          <!-- <router-link :to="'/article/list/' + item.name"> -->
          {{ item.name }}
          <!-- </router-link> -->
        </el-menu-item>
      </template>

      <el-menu-item
        index="杂粮委员会"
        @click="
          handlerOpenUrl('https://mp.weixin.qq.com/s/DclqsgPBl-vKv5xDPHw7fQ')
        "
      >
        杂粮委员会
      </el-menu-item>
      <el-menu-item index="下载分享" @click="handlerGoDonwload">
        下载分享
      </el-menu-item>
    </el-menu>
    <div v-if="device === 'desktop'" class="padding" />
  </div>
</template>

<style lang="scss" scoped>
.com {
  display: flex;
  .padding {
    flex: 1;
    background-color: rgb(84, 92, 100);
    border-bottom: solid 1px #e6e6e6;
  }
}
.el-menu-item {
  a {
    text-decoration: none;
  }
}

@media screen and (max-width: 992px) {
  .article{
    width: 96% !important;
  }
}
</style>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['categorys', 'device']),
    activeIndex() {
      if (this.$route.name === 'ArtilcleList') {
        return this.$route.params.type
      } else if (this.$route.name === 'FileList') {
        return '下载分享'
      } else {
        return ''
      }
    }
  },

  watch: {},
  methods: {
    handlerOpenUrl(url) {
      window.open(url)
    },
    goHome() {
      this.$router.push('/')
    },
    goDetialList(name) {
      this.$router.push('/article/list/' + name)
    },
    handlerGoDonwload() {
      this.$router.push('/article/file/list')
    }
  }
}
</script>
