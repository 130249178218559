import Vue from 'vue'
import { Carousel, CarouselItem, Input, Button, Message, Image, Divider, Loading, Menu, MenuItem } from 'element-ui'

Vue.prototype.$message = Message

Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Input.name, Input)
Vue.component(Button.name, Button)
Vue.component(Image.name, Image)
Vue.component(Divider.name, Divider)
Vue.component(Message.name, Message)
Vue.component(Menu.name, Menu)
Vue.component(MenuItem.name, MenuItem)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
