<template>
  <div class="app-header">
    <header class="menu" />
  </div>
</template>

<style lang="scss" scoped>
.app-header {
  width: 100vw;
  z-index: 9999;
  background-color: transparent;
  .menu {
    display: flex;
    line-height: 50px;
    .logo {
      width: 10%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .logo-img {
        height: 40px;
        display: inline-block;
      }
    }

    .menus {
      flex: 1;
      margin-left: 10px;
      .menu-item {
        padding: 0 10px;
      }
    }
    .menu-left {
      .menu-item {
        padding: 0 30px;
      }
    }

    a {
      text-decoration: none;
    }
    a:hover {
      color: #409eff;
    }
  }
}
</style>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          logo: '',
          name: 'xblog',
          navs: [
            {
              name: '首页',
              url: '/'
            }
          ]
        }
      }
    }
  },
  data() {
    return {
      curValue: this.value
    }
  }
}
</script>
