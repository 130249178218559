<template>
  <div id="app">
    <router-view>></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: -apple-system, SF UI Display, Arial, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, WenQuanYi Micro Hei, sans-serif;
  /* background-image: url('./assets/cloud_backgroud.png'); */
  background-repeat: no-repeat;
  background-color: rgb(213, 239, 223, 0.8);
  background-position: top center;
}

#app .wx {
  color: #04be02;
}

.app-mian {
  min-height: 80vh;
}

.top-wrap {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}
</style>
