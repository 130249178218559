<template>
  <footer v-if="device === 'desktop'" class="footer">
    <div>
      <span class="value beian">
        {{ domain.ipc_beian }}
      </span>
      <span v-if="domain.gongan_beian" class="value">
        {{ domain.gongan_beian }}
      </span>
      <span class="value"> {{ from_year }}@{{ domain.main_company }} </span>
    </div>
    <div v-if="domain.sub_company">
      <span>协办: </span><span>{{ domain.sub_company }} </span>
    </div>
    <div v-if="domain.contact_us">
      <span>联系我们: </span><span>{{ domain.contact_us }} </span>
    </div>
  </footer>
  <footer v-else class="footer">
    <div>
      <span class="value beian">
        {{ domain.ipc_beian }}
      </span>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppFooter',
  props: {
    domain: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters(['device'])
  },
  data() {
    return {
      from_year: this.domain.from_year || '2021'
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  //   height: 40px;
  line-height: 40px;
  background-color: #909399;
  color: #ffffff;
  text-align: center;
  .value {
    margin: 10px;
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
</style>
