<template>
  <div class="app-wrapper">
    <app-header v-model="header" />
    <div class="app-mian">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
    </div>
    <div class="app-footer">
      <app-footer :domain="domain" />
    </div>
  </div>
</template>

<script>
import AppHeader from './header'
import AppFooter from './footer'
import ResizeHandler from '@/layout/ResizeHandler'

export default {
  components: {
    AppHeader,
    AppFooter
  },
  mixins: [ResizeHandler],
  data() {
    return {
      header: {},
      domain: {}
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  async mounted() {
    const info = await this.$store.dispatch('app/getSiteInfo')
    this.header = info.header
    this.domain = info.domain
  }
}
</script>
