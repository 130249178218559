<template>
  <div class="app-wrapper">
    <common />
    <div class="app-mian">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
    </div>
    <div class="app-footer">
      <app-footer :domain="domain" />
    </div>
  </div>
</template>

<style>
@media screen and (max-width: 992px) {
  .content-wrap {
    width: 100%;
  }
  .qr-wrap {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .content-wrap {
    width: 70%;
    margin: 0 auto;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
</style>

<script>
import Common from '@/layout/SubCom/common.vue'
import AppFooter from './footer'
import ResizeHandler from '@/layout/ResizeHandler'

export default {
  components: {
    Common,
    AppFooter
  },
  mixins: [ResizeHandler],
  data() {
    return {
      header: {},
      domain: {}
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  async mounted() {
    const info = await this.$store.dispatch('app/getSiteInfo')
    this.header = info.header
    this.domain = info.domain
  }
}
</script>
