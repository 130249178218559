const getters = {
  // sidebar: state => state.app.sidebar,
  // device: state => state.app.device,
  // visitedViews: state => state.tagsView.visitedViews,
  // cachedViews: state => state.tagsView.cachedViews,

  // token: state => state.user.token,
  // avatar: state => state.user.avatar,
  // name: state => state.user.name,
  // roles: state => state.user.roles,
  // permission_routes: state => state.permission.routes
  qrcode: state => state.app.wechat.qrcode,
  wechat_name: state => state.app.wechat.name,
  logo: state => state.app.logo,
  categorys: state => state.app.categorys,
  device: state => state.app.device

}
export default getters
