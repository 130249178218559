<template>
  <div class="com">
    <div class="top-wrap">
      <top-logo />
    </div>

    <categroy />
  </div>
</template>
<script>
import TopLogo from '@/components/TopLogo'
import Categroy from './categroy'
export default {
  components: {
    TopLogo,
    Categroy
  }
}
</script>
