import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/AppMain'
import SubMain from '@/layout/SubMain'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'Home',
      component: () => import('@/views/home/index'),
      meta: {
        title: '首页'
      }
    }
    ]
  },
  {
    path: '/article',
    component: SubMain,
    children: [
      {
        path: '/article/:id',
        name: 'ArtilcleDetail',
        component: () => import('@/views/detail/index'),
        meta: {
          title: '文章详情'
        }
      },
      {
        path: '/article/list/:type',
        name: 'ArtilcleList',
        component: () => import('@/views/detail/list'),
        meta: {
          title: '文章列表'
        }
      },
      {
        path: '/article/file/list',
        name: 'FileList',
        component: () => import('@/views/detail/file'),
        meta: {
          title: '文件列表'
        }
      },
      {
        path: '/article/search/:query',
        name: 'ArticleSearch',
        component: () => import('@/views/detail/list'),
        meta: {
          title: '搜索结果'
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
