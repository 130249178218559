import request from '@/lib/request'

export async function getIndexData() {
  return request({
    url: '/api/client/index'
  })
}

export async function getConfig() {
  return request({
    url: '/api/client/config'
  })
}
